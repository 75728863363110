<template>
	<div class="body">
		<div>
			<div style="text-align: center;font-weight: bold;font-size: 18px;margin: 10px 0px;">
				工单详情信息
			</div>
			<van-form>
				<van-field v-model="from.ticketNum" label="工单编号:" readonly />
				<van-field v-model="from.ticketType" label="工单类别:" readonly />
				<van-field v-model="from.problemTypeName" label="问题类型:" readonly />
				<van-field v-model="from.questionName" label="问题名称:" readonly />
				<van-field v-model="from.contact" label="联系人:" readonly />
				<van-field v-model="from.contactPhone" label="手机号:" readonly >
					<template #button>
						<van-button @click="makePhoneCallEvent(from.contactPhone)" size="small" type="primary">拨号</van-button>
					</template>
				</van-field>
				<van-field v-model="from.waterCode" label="水表编号:" readonly />
				<van-field v-model="from.contactAddress" label="地址:" readonly />
				<van-field v-model="from.ticketDesc" label="问题描述:" rows="4" type="textarea" autosize readonly />
			</van-form>
		</div>
	</div>
</template>

<script>
	import request from "@/api/index.js";
	export default {
		name: '',
		components: {

		},
		props: {},
		data() {
			return {
				from: {
					ticketNum: '',
					ticketType: '',
					problemTypeName: '',
					questionName: '',
					contact: '',
					contactPhone: '',
					waterCode: '',
					contactAddress: '',
					ticketDesc: ''
				}
			}
		},
		computed: {},
		watch: {},
		created() {
			let num = this.$route.query.ticketNum;
			let typeA = this.$route.query.ticketType;
			if(num == undefined || num == ''){
				this.$toast.fail('工单编号为空')
			}else{
				request.get(process.env.VUE_APP_GZ_PHOENIX_URL + '/wechat/publicAccount/getTicketInfoByTicketNum', {
					params: {
						ticketNum: num
					}
				}).then(res => {
					if (res.code == 200) {
						this.from = res.data
						this.from.ticketType = typeA
					} else if (res.code == 501){
						this.$toast.fail(res.msg)
					} else {
						this.$toast.fail('获取工单详情失败')
					}
				}).catch(err => {
					this.$toast.fail(err.message)
				})
			}
		},
		mounted() {

		},
		methods: {
			// 打电话
			makePhoneCallEvent: function(phone) {
				if(!phone) {
					return
				}
				window.location.href = 'tel:' + phone
			},

		},
	}
</script>
<style lang='scss' scoped>

</style>